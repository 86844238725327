export class LazyLoader {

  static LAZY_LOAD_CLASS = 'lazyload';
  static LAZY_LOADED_CLASS = 'lazyloaded';

  targets: NodeListOf<HTMLElement>;
  svgTargets: NodeListOf<SVGImageElement>;

  init(): void {
    this.targets = document.querySelectorAll(`.${LazyLoader.LAZY_LOAD_CLASS}`);
    if (this.targets.length) {
      for (let i = 0; i < this.targets.length; i++) { // tslint:disable-line:prefer-for-of
        this.lazyload(this.targets[i]);
      }
    }
    this.svgTargets = document.querySelectorAll(`.${LazyLoader.LAZY_LOAD_CLASS}-svg`);
    if (this.svgTargets.length) {
      for (let i = 0; i < this.svgTargets.length; i++) { // tslint:disable-line:prefer-for-of
        this.asyncLoadSvg(this.svgTargets[i]);
      }
    }
  }

  private asyncLoadSvg = (target: SVGImageElement): void => {
    const resolution = parseInt(target.getAttribute('data-resolution') || '0', 10);
    const image = target.querySelector('image');

    const applyLoadedState = () => {
      image.removeAttribute('data-href');
      target.classList.remove(LazyLoader.LAZY_LOAD_CLASS);
      target.classList.add(LazyLoader.LAZY_LOADED_CLASS);
    };

    if (!resolution || resolution < window.innerWidth) {
      image.onload = () => {
        image.onload = undefined;
        applyLoadedState();
      };
      const href = image.getAttribute('data-href');
      if (href) {
        image.setAttribute('href', href);
      }
    } else {
      applyLoadedState();
    }
  };

  private lazyload = (target: HTMLElement): void => {
    const io = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const element = entry.target as HTMLElement;
          this.asyncLoad(element);

          observer.disconnect();
        }
      });
    });

    io.observe(target);
  };

  private asyncLoad = (element: HTMLElement): void => {
    const src = element.dataset['src'];
    const bg = element.dataset['bg'];
    const srcset = element.dataset['srcset'];

    if (src) {
      element.setAttribute('src', src);
    }
    if (bg) {
      element.style.backgroundImage = `url(${bg})`;
    }
    if (srcset) {
      element.setAttribute('srcset', srcset);
    }

    element.classList.remove(LazyLoader.LAZY_LOAD_CLASS);
    element.classList.add(LazyLoader.LAZY_LOADED_CLASS);
  };
}

(() => {
  const lazy = new LazyLoader();
  if (/complete|interactive|loaded/.test(document.readyState)) {
    lazy.init();
  } else {
    document.addEventListener('DOMContentLoaded', () => lazy.init(), false);
  }
})();
